<template>
	<div class="bird-dog-bonus">
		<v-progress-linear v-if="!referralsLoaded || cmsPageLoading" indeterminate />

		<div v-else-if="referralsLoaded && referralsError" class="d-flex justify-center mt-4">
			<v-alert type="error" text max-width="800px">
				<p>Oops! There was a problem loading Bird-Dog Referrals data.</p>
				<p class="mb-0">
					Please try reloading the page
					<v-btn icon color="q_blue_1" class="ml-1" :disabled="reloadDisable" @click="resetAndReload">
						<v-icon small>fas fa-sync-alt</v-icon>
					</v-btn>
				</p>
			</v-alert>
		</div>

		<template v-else>
			<CmsPage
				v-if="!hasAdminPermissions && doShowCmsPage"
				:key="cmsPageSlug"
				:page="cmsPage"
			/>

			<div v-else class="d-flex align-center">
				<h1 class="bird-dog-bonus__title">Bird-Dog Bonus</h1>
				<v-btn icon color="q_blue_1" class="ml-1" :disabled="reloadDisable" @click="reloadData">
					<v-icon small>fas fa-sync-alt</v-icon>
				</v-btn>
			</div>

			<div v-if="!hasAdminPermissions" class="d-flex mt-8" :class="doShowCmsPage ? 'justify-center' : ''">
				<v-btn
					class="bird-dog-tracking__primary-btn" elevation="0"
					:class="isMobileView && 'px-2'"
					color="q_blue_1"
					@click="submitNewReferral"
				>
					<template v-if="!isMobileView">
						<v-icon>fas fa-plus</v-icon>
						<v-divider class="mx-2" color="white" vertical />
					</template>
					Submit New Bird-Dog Referral
				</v-btn>
			</div>

			<template v-if="!hasAdminPermissions && !doShowCmsPage">
				<div class="bird-dog-bonus__agent-cards">
					<BirdDogBonusTotal
						:total="bonusTotal"
						:loading="bonusTotalLoading"
						:error="bonusTotalError"
						class="agent-cards__item"
					/>
					<BirdDogBonusAccounts
						:items="bonusAccounts"
						:loading="bonusAccountsLoading"
						:error="bonusAccountsError"
						class="agent-cards__item"
					/>
				</div>
			</template>

			<BirdDogStatusSummary
				v-if="hasAdminPermissions"
				:items="statusSummary"
				:loading="statusSummaryLoading"
				:error="statusSummaryError"
				class="mt-8"
			/>

			<BirdDogReferralsTable
				v-if="hasAdminPermissions || (!hasAdminPermissions && !doShowCmsPage)"
				v-model="search"
				:items="referrals"
				:options="options"
				:filters="filters"
				:default-filters="defaultFilters"
				:server-items-length="serverItemsLength"
				:has-admin-permissions="hasAdminPermissions"
				:loading="referralsLoading"
				:error="referralsError"
				class="mt-8"
				@edit-referral="editReferral"
				@search="searchData"
				@update-filters="filters = $event"
				@update-options="options = $event"
				@export-results="exportResults"
			/>

			<div class="mt-3">
				For questions regarding the Bird-Dog program or specific referrals, email
				<a class="bird-dog-tracking__link" href="mailto:b2b@quility.com">b2b@quility.com</a>.
			</div>
		</template>

		<BirdDogReferralForm
			v-if="doShowReferralForm"
			v-model="doShowReferralForm"
			:mode="birdDogFormMode"
			:data="birdDogFormData"
			:has-admin-permissions="hasAdminPermissions"
			@submit="reloadData"
			@save="reloadData"
		/>
	</div>
</template>

<script>
import { debounce } from 'vue-debounce'

import QuilityAPI from '@/store/API/QuilityAPI'
import Page from '@/store/Models/Page'

import CmsPage from '@/CMS/views/index.vue'
import BirdDogReferralsTable from '../components/BirdDogReferralsTable.vue'
import BirdDogStatusSummary from '../components/BirdDogStatusSummary.vue'
import BirdDogReferralForm from '../components/BirdDogReferralForm.vue'
import BirdDogBonusTotal from '../components/BirdDogBonusTotal.vue'
import BirdDogBonusAccounts from '../components/BirdDogBonusAccounts.vue'

import { loadBirdDogReferrals, loadBirdDogBonusTotal, loadBirdDogBonusSummary } from '../api/index'

const FORM_MODE = {
	submit: 'submit',
	edit: 'edit',
}

const defaultFilters = {
	Status: [],
	Qualified: [],
}

export default {
    components: {
		BirdDogReferralsTable,
		BirdDogStatusSummary,
		BirdDogReferralForm,
		BirdDogBonusTotal,
		BirdDogBonusAccounts,
		CmsPage,
	},
	props: {
		agent: { type: Object },
	},
    data () {
        return {
			search: '',
			defaultFilters,
			filters: { ...defaultFilters },
			options: {
				sortBy: ['DateAdded'],
				sortDesc: [true],
				page: 1,
				itemsPerPage: 25,
			},
			serverItemsLength: undefined,

			referrals: [],
			referralsLoading: false,
			referralsLoaded: false,
			referralsError: false,

			bonusTotal: null,
			bonusTotalLoading: false,
			bonusTotalError: false,

			bonusAccounts: null,
			bonusAccountsLoading: false,
			bonusAccountsError: false,

			statusSummary: null,
			statusSummaryLoading: false,
			statusSummaryError: false,

			doShowReferralForm: false,
			birdDogFormMode: undefined, // submit | edit | undefined
			birdDogFormData: null,

			cmsPageSlug: 'quility-b2b',
			cmsPageLoading: false,
			cmsPageError: false,
			cmsPage: null,
        }
    },
	created () {
		this.reloadData()
	},
    computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		agentCode () {
			return this.agent ? this.agent.AgentCode : this.user.Agent.AgentCode
		},
		hasAdminPermissions () {
			return this.hasRole(['SuperAdmin', 'Staff'])
		},
		reloadDisable () {
			return this.referralsLoading || this.cmsPageLoading
		},
		doShowCmsPage () {
			return this.referralsLoaded && !this.referrals.length && this.cmsPage
		},
    },
	watch: {
		agentCode () {
			this.resetAndReload()
		},
		doShowReferralForm (v) {
			if (!v) {
				this.birdDogFormMode = undefined
				this.birdDogFormData = null
			}
		},
		referralsLoaded (newValue, oldValue) {
			if (newValue && !oldValue && !this.referrals.length) {
				this.loadCmsPage()
			}
		},
		filters: {
			deep: true,
			handler () {
				this.options.page = 1
				this.loadReferrals()
			},
		},
		options: {
			deep: true,
			handler () {
				this.loadReferrals()
			},
		},
	},
	methods: {
		resetAndReload () {
			this.referralsLoaded = false
			this.filters = { ...this.defaultFilters }
			this.options.page = 1
			this.search = ''
			this.serverItemsLength = undefined
			this.referrals = []
			this.statusSummary = null
			this.bonusAccounts = null
			this.bonusTotal = null
			this.reloadData()
		},
		reloadData () {
			this.loadReferrals()
			if (this.hasAdminPermissions) {
				this.loadSummary()
			} else {
				this.loadTotal()
				this.loadAccounts()
			}
		},
		searchData () {
			this.options.page = 1
			this.loadReferrals()
		},
		async loadCmsPage () {
			const page = Page.query().where('slug', this.cmsPageSlug).first()
			if (page) {
				this.cmsPage = { ...page, template: 'default' }
				return Promise.resolve()
			}
			this.cmsPageLoading = true
			this.cmsPageError = false
			try {
				await QuilityAPI.getPageWithSlug(this.cmsPageSlug)
				const page = Page.query().where('slug', this.cmsPageSlug).first()
				this.cmsPage = { ...page, template: 'default' }
			} catch (err) {
				this.showError('Oops! There was a problem loading the cms page.', err.message)
				this.cmsPageError = true
			} finally {
				this.cmsPageLoading = false
			}
		},
		async loadTotal () {
			this.setBonusTotalLoadingStatus(true, false)
			const res = await loadBirdDogBonusTotal(this.agentCode)
			if (res.error) {
				this.setBonusTotalLoadingStatus(false, true)
				this.showError(`Oops! There was a problem loading Bird-Dog Bonus Total.<br>`, res.message)
			} else {
				this.bonusTotal = res.total
				this.setBonusTotalLoadingStatus(false, false)
			}
		},
		async loadAccounts () {
			this.setAccountsLoadingStatus(true, false)
			const res = await loadBirdDogBonusSummary(this.agentCode)
			if (res.error) {
				this.setAccountsLoadingStatus(false, true)
				this.showError(`Oops! There was a problem loading Bird-Dog Accounts data.<br>`, res.message)
			} else {
				this.bonusAccounts = [...res.data]
				this.setAccountsLoadingStatus(false, false)
			}
		},
		async loadSummary () {
			this.setSummaryLoadingStatus(true, false)
			const res = await loadBirdDogBonusSummary(this.agentCode)
			if (res.error) {
				this.setSummaryLoadingStatus(false, true)
				this.showError(`Oops! There was a problem loading Bird-Dog Status Summary.<br>`, res.message)
			} else {
				this.statusSummary = [...res.data]
				this.setSummaryLoadingStatus(false, false)
			}
		},
		loadReferrals: debounce(async function () {
			this.setReferralsLoadingStatus(true, false)
			const opt = {
				...this.options,
				...this.filters,
				search: this.search,
				Status: this.filters.Status.join(','),
				Qualified: this.filters.Qualified.join(','),
			}
			const res = await loadBirdDogReferrals(this.agentCode, opt)
			if (res.error) {
				this.setReferralsLoadingStatus(false, true)
				this.showError(`Oops! There was a problem loading Bird-Dog Referrals data.<br>`, res.message)
			} else {
				this.referrals = [...res.data]
				this.serverItemsLength = res.meta?.total
				this.setReferralsLoadingStatus(false, false)
			}
			this.referralsLoaded = true
		}, 500),
		exportResults () {
			const params = {
				...this.options,
				...this.filters,
				search: this.search,
				sortBy: this.options.sortBy.join(','),
				sortDesc: this.options.sortDesc.join(','),
				Status: this.filters.Status.join(','),
				Qualified: this.filters.Qualified.join(','),
				itemsPerPage: 'csv',
			}
			this.dispatchJob({
                method: 'GET',
                path: `/api/bird_dog_referral/${this.agentCode}`,
                params,
                JobName: 'Bird-Dog Referrals',
            })
		},
		setReferralsLoadingStatus(loading, error) {
			this.referralsLoading = loading
			this.referralsError = error
		},
		setBonusTotalLoadingStatus(loading, error) {
			this.bonusTotalLoading = loading
			this.bonusTotalError = error
		},
		setAccountsLoadingStatus(loading, error) {
			this.bonusAccountsLoading = loading
			this.bonusAccountsError = error
		},
		setSummaryLoadingStatus(loading, error) {
			this.statusSummaryLoading = loading
			this.statusSummaryError = error
		},
		submitNewReferral () {
			this.birdDogFormMode = FORM_MODE.submit
			this.birdDogFormData = null
			this.doShowReferralForm = true
		},
		editReferral (item) {
			this.birdDogFormMode = FORM_MODE.edit
			this.birdDogFormData = { ...item }
			this.doShowReferralForm = true
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../style/style.scss';

$block: bird-dog-bonus;

.#{$block} {
	--row-gap: 2rem;
	--col-gap: 2rem;

	&__title {
		margin-bottom: 0;
		font-size: calc(28px + (36 - 30) * ((100vw - 320px) / (1600 - 320)));
		font-weight: bold !important;
		letter-spacing: normal;
	}

	&__agent-cards {
		width: 100%;
		margin-top: 2rem;
		display: flex;
		flex-wrap: wrap;
		row-gap: var(--row-gap);
		column-gap: var(--col-gap);
	}

	.agent-cards__item {
		max-width: 22rem;
		flex: 1 1 19rem;
	}
}
</style>
